.container {
  position: relative;
  width: 100%;
}

.hidden {
  display: none;
}

.labels {
  display: none;
  flex-wrap: wrap;
}

.label {
  background: transparent;
  color: black;
  font-family: var(--global-font);
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: center;
  text-transform: uppercase;
  width: calc(100% / 6);
}

.search-box input {
  /* padding: 8px 8px 8px 0px; */
  -webkit-animation-duration: 10ms;
  -webkit-animation-name: mui-auto-fill-cancel;
  -webkit-tap-highlight-color: transparent;
  -webkit-transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  animation-duration: 10ms;
  animation-name: mui-auto-fill-cancel;
  background: none;
  border-radius: 5px;
  border: 0;
  box-sizing: content-box;
  color: currentColor;
  color: white;
  display: block;
  font: inherit;
  height: 30px;
  letter-spacing: inherit;
  margin: 0;
  overflow: hidden;
  text-indent: 30px;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.search-box input::placeholder {
  color: white;
  opacity: 0.7;
}

.search-box button {
  display: none;
}

.hide {
  display: none;
}

.default-results {
  display: unset;
  overflow-y: auto;
  height: max-content;
}
