.group-title {
  display: flex;
  align-items: center;
  margin: var(--ups-canvas-gap);
}

.label {
  font-size: 18px;
  letter-spacing: 0.8px;
  padding-left: var(--ups-canvas-gap);
  user-select: none;
}

.top-right-icon-row {
  align-items: center;
  display: flex;
  margin-left: auto;
}

.carousel {
  border-radius: var(--ups-paper-border-radius);
  margin: calc(var(--ups-canvas-gap) * 2);
  margin-left: calc(var(--ups-canvas-gap) * 4);
  zoom: 0.5;
}

@media (max-width: calc(480px)) {
  .carousel {
    margin-left: unset;
    margin-right: unset;
    border-bottom-right-radius: unset;
    border-top-right-radius: unset;
  }
}
@media (prefers-color-scheme: light) {
  .carousel {
    background-color: var(--ups-paper-background-color);
  }
}
@media (prefers-color-scheme: dark) {
  .carousel {
    background-color: var(--ups-backdrop-background-color);
  }
}

.carousel-populated {
  composes: carousel;
  padding: var(--ups-backdrop-gap) 0;
}

.carousel-item {
  display: flex;
  justify-content: center;
}
