.report-links {
  align-self: left;
  color: blue;
  cursor: pointer;
  margin: 0;
  text-decoration: none;
}

.report-links:hover {
  color: 'black';
}

.menu-icon {
  background-color: var(--ups-backdrop-background-color);
  border-radius: 100px;
  border-width: 1px;
  border-color: black;
  position: absolute;
  right: var(--ups-backdrop-gap);
  bottom: var(--ups-backdrop-gap);
}
