.container {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}

.brand {
  display: flex;
  width: 240px;
  align-self: center;
  justify-content: center;
}

.login-box {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 300px;
}

.form-control {
  width: 100%;
}

.input {
  width: 100%;
}

.input-group {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.label {
  flex-grow: 1;
  flex-shrink: 0;
  font-weight: bold;
  margin-bottom: 10px;
  align-self: flex-start;
}

.options {
  display: flex;
  flex-direction: column;
}

.remember-pass {
  align-items: center;
  display: flex;
  justify-content: center;
}

.remember-pass:hover {
  cursor: pointer;
}

.submit-or-forgot {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.submit {
  width: 100%;
}
.sign-google {
  align-self: center;
}

.error {
  background-color: purple;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  padding: 5px;
  text-align: center;
}

@media (min-width: 600px) {
  .login-box {
    padding: 40px;
    width: 420px;
  }
}

@media (min-width: 425px) {
  .label {
    display: unset;
    margin-bottom: unset;
    align-self: unset;
  }

  .input-group {
    flex-direction: row;
  }
}
