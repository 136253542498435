.container {
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 0px 64px;
}

.brand {
  display: flex;
  width: 240px;
  align-self: center;
  justify-content: center;
}

.box {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 300px;
}
