:root {
  --max-content-w: 720px;
}

div {
  transition: opacity 0.12s ease-in;
}

html {
  background-color: var(--ups-backdrop-background-color);
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/* #region react-native-vector-icons */
@font-face {
  font-family: 'AntDesign';
  src: local('AntDesign'), url(./fonts/rnvi/AntDesign.ttf);
}
@font-face {
  font-family: 'Fontisto';
  src: local('Fontisto'), url(./fonts/rnvi/Fontisto.ttf);
}
@font-face {
  font-family: 'Entypo';
  src: local('Entypo'), url(./fonts/rnvi/Entypo.ttf);
}
@font-face {
  font-family: 'Foundation';
  src: local('Foundation'), url(./fonts/rnvi/Foundation.ttf);
}
@font-face {
  font-family: 'EvilIcons';
  src: local('EvilIcons'), url(./fonts/rnvi/EvilIcons.ttf);
}
@font-face {
  font-family: 'Ionicons';
  src: local('Ionicons'), url(./fonts/rnvi/Ionicons.ttf);
}
@font-face {
  font-family: 'Feather';
  src: local('Feather'), url(./fonts/rnvi/Feather.ttf);
}
@font-face {
  font-family: 'MaterialCommunityIcons';
  src: local('MaterialCommunityIcons'),
    url(./fonts/rnvi/MaterialCommunityIcons.ttf);
}
@font-face {
  font-family: 'FontAwesome';
  src: local('FontAwesome'), url(./fonts/rnvi/FontAwesome.ttf);
}
@font-face {
  font-family: 'MaterialIcons';
  src: local('MaterialIcons'), url(./fonts/rnvi/MaterialIcons.ttf);
}
@font-face {
  font-family: 'FontAwesome5_Brands';
  src: local('FontAwesome5_Brands'), url(./fonts/rnvi/FontAwesome5_Brands.ttf);
}
@font-face {
  font-family: 'Octicons';
  src: local('Octicons'), url(./fonts/rnvi/Octicons.ttf);
}
@font-face {
  font-family: 'FontAwesome5_Regular';
  src: local('FontAwesome5_Regular'), url(./fonts/rnvi/FontAwesome5_Regular.ttf);
}
@font-face {
  font-family: 'SimpleLineIcons';
  src: local('SimpleLineIcons'), url(./fonts/rnvi/SimpleLineIcons.ttf);
}
@font-face {
  font-family: 'FontAwesome5_Solid';
  src: local('FontAwesome5_Solid'), url(./fonts/rnvi/FontAwesome5_Solid.ttf);
}
@font-face {
  font-family: 'Zocial';
  src: local('Zocial'), url(./fonts/rnvi/Zocial.ttf);
}
/* #endregion react-native-vector-icons */

@font-face {
  font-family: 'Manrope';
  src: local('Manrope'), url(../../assets/Manrope.ttf) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'DM Sans';
  src: local('DM Sans'), url(../../assets/DMSans.ttf) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

*::-webkit-scrollbar {
  display: none;
}

@media (prefers-color-scheme: light) {
  html {
    background-color: var(--ups-backdrop-background-color) !important;
  }

  /* TODO: Use theme on surface color */
  input[disabled] {
    color: black !important;
    user-select: none !important;
  }
}

@media (prefers-color-scheme: dark) {
  html {
    background-color: var(--ups-canvas-background-color) !important;
  }

  /* TODO: Use theme on surface color */
  input[disabled] {
    color: white !important;
    user-select: none !important;
  }
}

* {
  font-family: var(--ups-font-family), sans-serif;
  scroll-behavior: smooth;
}

*::-webkit-search-results-button {
  display: none !important;
}

*::-webkit-search-cancel-button {
  display: none !important;
}

*::-webkit-search-results-decoration {
  display: none !important;
}

*::-webkit-search-decoration {
  display: none;
}

*::-webkit-scrollbar {
  width: 12px;
}

.no-scroll {
  overflow-y: hidden;
  scroll-behavior: 'unset' !important;
}

.no-scroll::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pay-per-month {
  font-size: 32px;
  font-weight: bold;
  color: black;
}

.slick-dots li button:before {
  color: blue !important;
}

[class$='-MuiInputBase-root'] {
  margin-top: 'unset';
}

/* Already handled via RN code. */
input,
textarea {
  outline: none !important;
}

@media only print {
  #root {
    display: none !important;
  }
  body {
    display: block !important;
  }

  body::after {
    content: 'Please use the pdf download button.';
    font-size: 46px;
    width: 100%;
    height: 100%;
    display: flex;
    margin-top: 50%;
  }
}

.react-multiple-carousel__arrow {
  z-index: 0;
}

.dz-ui-footer,
div.dz-ui-header > span {
  cursor: pointer;
  user-select: none;
}
