.align-self-center {
  align-self: center;
}

.card {
  background-color: var(--ups-paper-background-color);
  border: var(--ups-paper-border-width) solid var(--ups-paper-border-color);
  border-radius: var(--ups-border-radius);
  display: flex;
  padding: var(--ups-paper-padding-vertical) var(--ups-paper-padding-horizontal);
  /* box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.24); */
}

.canvas {
  background-color: var(--ups-canvas-bg);
}

.content {
  display: flex;
  flex-direction: column;
  gap: 36px;
  margin: 0px auto;
  max-width: 1024px;
  padding: 36px 12px;
}

.content-holder {
  margin: auto;
  max-width: var(--max-content-w);
}

.flex {
  display: flex;
}

.unselectable {
  user-select: none;
}

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}

.pad-v-12 {
  padding-bottom: 12px;
  padding-top: 12px;
}

.row {
  composes: flex;
  flex-direction: row;
}

.row-centered {
  composes: row;
  align-items: center;
}

.row-justify-between {
  composes: row;
  justify-content: space-between;
}

.row-justify-between-100 {
  composes: row-justify-between;
  width: 100%;
}

.row-wrap {
  composes: row;
  flex-wrap: wrap;
}

.row-wrap-gapped {
  composes: row-wrap;
  gap: var(--ups-gap);
}

.card-content {
  composes: card;
  composes: row-wrap-gapped;
  composes: row-justify-between;
}

.text-centered {
  text-align: center;
}

.text-undecorated {
  text-decoration: none;
}

.width-100 {
  width: 100%;
}

.display-none {
  display: none;
}

.visibility-hidden {
  visibility: hidden;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.position-relative {
  position: relative;
}

/* Composite */

.col {
  composes: flex;
  flex-direction: column;
}
