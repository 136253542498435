.backdrop {
  background-color: grey; /* Fallback color */
  background-color: rgba(0, 0, 0, 0.48); /* Black w/ opacity */
  height: 100%; /* Full height */
  left: 0;
  overflow: auto; /* Enable scroll if needed */
  padding-top: 64px; /* Location of the box */
  position: fixed; /* Stay in place */
  top: 0;
  width: 100%; /* Full width */
}

.backdrop-opaque {
  composes: backdrop;
  background-color: var(--ups-backdrop-background-color);
}

.dialog-header {
  position: relative;
  text-align: center;
}

.dialog-content {
  margin: auto;
  max-width: 640px;
  min-width: 320px;
}

.dialog-content-canvas {
  composes: dialog-content;
  background-color: var(--ups-canvas-background-color);
  border: 1px solid var(--ups-canvas-border-color);
  border-radius: var(--ups-canvas-border-radius);
  padding: var(--ups-canvas-gap);
  padding-bottom: 120px; /* TODO: Why is it needed? */
}

.close-container {
  background-color: #ddd;
  border: #bbb 2px solid;
  border-radius: 80px;
  width: 48px;
  height: 48px;
  position: absolute;
  right: 64px;
  text-align: center;
  top: 64px;
}

.close-container:hover {
  background-color: #eee;
  cursor: pointer;
}

.close-container-canvas {
  composes: close-container;
  background-color: transparent;
  border: unset;
  right: 0px;
  top: 0px;
}

.close-container-canvas:hover {
  background-color: transparent;
}

.close {
  margin: auto;
  color: #222;
  font-size: 28px;
  font-weight: bold;
  user-select: none;
}
