.breadcrumbs {
  composes: row-centered from '../../global.module.css';
  /* align-items: center; */
  background-color: var(--ups-backdrop-highlight);
  display: flex;
  flex-direction: row;
  /* Keep in sync with the value in <NavMenu /> */
  height: 28px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.breadcrumbs-content {
  composes: row-centered from '../../global.module.css';
  color: #fff;
  padding: 0 24px;
  text-decoration: none;
  user-select: none;
}

.text {
  color: #fff;
  font-family: var(--ups-font-family);
  font-size: 14px;
  user-select: none;
}
